<template>
  <base-section id="features" class="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card align="center" dark v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatures",

  data: () => ({
    cards: [
      {
        icon: "mdi-earth",
        title: "Deliver Worldwide",
        text: "In today’s connected world, telecoms services extend beyond country borders, so our technology solutions will work for you, wherever you are.",
      },
      {
        icon: "mdi-cellphone-message",
        title: "Mobile Experts",
        text: "Through our solutions, we have helped shape the evolution of mobiles, from the time they were just used for calling and texting to the days of the smartphone.",
      },
      {
        icon: "mdi-lifebuoy",
        title: "Customer Support",
        text: "As your technology partner, we'll always be there to assist you with your business. We know that's the only way to keep you happy and that's something we really do care about!",
      },
      {
        icon: "mdi-puzzle-outline",
        title: "real-time statistics",
        text: "Track your business performance in real-time to make intelligent and timely decisions.",
      },
    ],
  }),
};
</script>
